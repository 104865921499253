const panel = (type, options) => ({ options: { type, ...options } })

export const ANOTHER_LOGIN = 'loggedFromAnotherPlace'
export const CONNECTION_LOST = 'connectionLost'
export const UPLOAD_DIALOG_MEDIA = 'uploadDialogMedia'
export const DIALOG_MEDIA_BUY_CONFIRM = 'dialogMediaBuyConfirm'
export const MAIN_MENU_PANEL = 'mainMenu'
export const USER_PANEL = 'user'
export const CALL_PANEL = 'callPanel'
export const RATING_PANEL = 'ratingPanel'
export const AWARDS_PANEL = 'awardsPanel'
export const VOICING_PANEL = 'voicingPanel'
export const DIALOG_SETTINGS_PANEL = 'dialogsSettingsPanel'

export const AUTO_MESSAGES_PANEL = 'autoMessagesPanel'
export const DATING_INSTRUCTIONS_PANEL = 'datingInstructionsPanel'

export const AWARD_VIEW_PANEL = 'awardViewPanel'
export const GAME_CARDS_PANEL = 'gameCardsPanel'
export const VOICE_SELECT_PANEL = 'voiceSelect'
export const EMAIL_PANEL = 'emailPanel'
export const AI_SETTINGS_PANEL = 'aISettings'
export const ENERGY_PANEL = 'energyPanel'
export const USERS_LIST = 'usersList'
export const CARD_GAME_INFO_PANEL = 'cardGameInfoPanel'
export const PROFILE_CREATION_PANEL = 'profileCreationPanel'

export const POST_CREATION_PANEL = 'postCreationPanel'

export const RATING_ADD = 'rating_add'
export const RATING_VIEW = 'rating_view'
export const ALL_SHOWCASE = 'allShowcase'
export const BUY_VIP = 'getVip'
export const SHOP = 'shop'
export const TOP_PANEL = 'topPanel'

export const askPrivatePanel = panel('askPrivate')
export const loginPanel = panel('login')
export const settingsPanel = panel('settings')
export const moderCheckPanel = userId => panel('moderCheck', { userId })
export const moderEditPanel = (postId, size = 740, postCreatePanel = false, forAccessBlock = '', simplePost = false, isProfile = false) => panel('moderEdit', {
  postId,
  size: postCreatePanel ? 360 : size,
  postCreatePanel,
  forAccessBlock,
  simplePost,
  isProfile
})
export const profilePanel = panel('profile')
export const payoutPanel = panel('payout')
export const changeCamPanel = panel('changeCam')
export const changeMicPanel = panel('changeMic')
export const currencyPanel = panel('currency')
export const getVipPanel = panel(BUY_VIP)
export const exchangeCoinsPanel = panel('exchangeCoins')
export const payoutInputPanel = (id = '') => panel('payoutInput', { id })
export const languagePanel = panel('language')
export const pushSettingsPanel = panel('pushSettings')
export const forgotPasswordPanel = panel('forgotPassword', { form: 'resetPassword' })
export const forgotPasswordStep2Panel = panel('forgotPassword', { form: 'resetPasswordStep2' })
export const otherDialogs = (id = '') => panel('otherDialogs', { id })
export const otherDialog = (otherUid = '', dialog_id = '') => panel('otherDialog', { otherUid, dialog_id })
export const dialogsList = panel('dialogsList')
export const buffPanel = (buffId, userId, fromUid) => panel('buff', { buffId, userId, fromUid })
export const dialogPanel = (id = '', forceDialogID = '') => panel('dialog', { id, forceDialogID })
export const spamPanel = (id = '') => panel('spam', { id })
export const shopPanel = panel(SHOP)
export const giftPanel = (giftId, toId, withSend) => panel('gift', { giftId, toId, withSend, flexMinHeight: true })
export const giftSetsPanel = toId => panel('giftSets', { toId })
export const giftSetPanel = (setId, toId) => panel('giftSet', { setId, toId })
export const privateThemesPanel = panel('privateThemes')
export const stickersPanel = dialog_id => panel('stickers', { dialog_id })
export const feedFilterPanel = panel('feedFilter')
export const hyvorCommentsPanel = postId => panel('hyvorComments', { postId })
export const messageActionsPanel = panel('messageActions')
export const adminPanel = (id = '') => panel('admin', { id: id })
export const userPanel = (id = '', showReply = false) => panel('user', {
  id: id,
  flexMinHeight: true,
  showReply: showReply
})
export const subPermissionsPanel = (id = '', title = '') => panel('subPermissions', { id, title })
export const subscriptionPanel = (id = '', subLvl = null) => panel('subscription', { id, subLvl, flexMinHeight: true, })
export const subRequirementsPanel = (uid = '') => panel('subRequirements', { uid })
export const postOptionsPanel = (id = '', uid = '') => panel('postOptions', { id, uid })
export const complaintPanel = (id = '', typeId = '') => panel('complaint', { id, typeId })
export const usersListPanel = (id = '') => panel('usersList', { id })//id here is list id(example: 'fans' or 'all')
export const loggedFromAnotherPlacePanel = panel(ANOTHER_LOGIN)
export const connectionLostPanel = panel(CONNECTION_LOST)
export const countryBanPanel = panel('countryBan')
export const showStartPanel = panel('showStart')
export const proTranslationsPanel = panel('proTranslations')
export const changeEmailPanel = panel('changeEmail', { form: 'changeEmail' })
export const selectPostTagsPanel = (id = '') => panel('selectPostTags', { id })
export const setLocationPanel = (id = '') => panel('setLocationPanel', { id })
export const changeEmailFirstPanel = panel('changeEmail', { form: 'changeEmailFirst' })
export const changeEmailPasswordPanel = panel('changePassword', { form: 'changeEmail' })
export const changePasswordPanelStepOne = panel('changePassword', { form: 'changePassword', step: 1 })
export const changePasswordPanelStepTwo = panel('changePassword', { form: 'changePassword', step: 2 })
export const mainMenuPanel = panel('mainMenu')
export const rateUsPanel = panel('rateUs', { flexMinHeight: true })
export const allowNsfwPanel = contentType => panel('allowNsfw', { contentType: contentType, flexMinHeight: true })
export const shareAndEarnPanel = panel('shareAndEarn')
export const changeSubscriptionPanel = (id = '') => panel('changeSubscription', { id })
export const callPanel = (uid = '', incoming = false, themeId = 'romantic_date') => panel(CALL_PANEL, {
  uid,
  incoming,
  themeId,
  flexMinHeight: true
})
export const awardsPanel = (group, slot, setInSlotMode, uid) => panel(AWARDS_PANEL, { group, slot, setInSlotMode, uid })
export const awardViewPanel = (awardId, uid, slot, strength) => panel(AWARD_VIEW_PANEL, {
  awardId,
  uid,
  slot,
  strength
})
// export const gameCardsPanelPanel = ( ) => panel(GAME_CARDS_PANEL, { flexMinHeight:true })
export const quizPanel = () => panel('quizPanel', { flexMinHeight: true })
export const lvlPanel = (uid = '', flexMinHeight = true) => panel('lvlPanel', { uid, flexMinHeight })
export const genderSelectPanel = () => panel('genderSelectPanel', { flexMinHeight: true })
export const firstIncomePanel = () => panel('firstIncomePanel', { flexMinHeight: true })
export const postEditPanel = (id = '') => panel('postEdit', { id: id })
export const topPanel = (topId = '', history = false, flexMinHeight = false) => panel(TOP_PANEL, {
  topId,
  flexMinHeight,
  history
})
export const uploadDialogMedia = (uid = '', dialogId = '', flexMinHeight = false) => panel('uploadDialogMedia', {
  uid,
  dialogId,
  flexMinHeight
})
export const dialogMediaBuyConfirm = (dialog_id = '', postId = '', cost = 0, previewUrl = '', mediaTypeVideo = false, flexMinHeight = true) => panel(DIALOG_MEDIA_BUY_CONFIRM, {
  dialog_id,
  postId,
  cost,
  previewUrl,
  mediaTypeVideo,
  flexMinHeight
})
export const donatePanel = (donationType = '', donationLink = '', uid = '', flexMinHeight = true) => panel('donatePanel', {
  donationType,
  donationLink,
  uid,
  flexMinHeight
})
export const ratingPanel = (uid = '', ratingType = '') => panel(RATING_PANEL, { uid, ratingType, flexMinHeight: true })
