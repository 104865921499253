import { isDesktopSkin, isMe, isMobileSkin } from './checks'
import { callSequence } from './StoreManager'
import usePostersStore from '../zustand/usePostersStore'

let scrollPositions = {}

const mainScrollId = 'MainScroll'
const POSTERS_SCROLL = 'posters'
const POSTERS_GREED = 'postersGreed'


export function setScreenInPosition(key, withAnimate) {
  let position = 0
  if (!isMe(key)) {
    position = scrollPositions[key] || 0
  }
  if (isMe(key) || key === POSTERS_SCROLL) {
    callSequence('app.setAnimatedTopBar', { withAnimate: !!withAnimate })
    callSequence('app.hideTopBar', { value: position > 300 })
  }

  if (isDesktopSkin()) {
    const element = document.getElementById(POSTERS_GREED) || document.getElementById(mainScrollId)
    if (element) {
      element.scrollTop = position
    } else {
      window.scrollTo(0, position)
    }
  } else {
    window.scrollTo(0, position)
  }
}

export function rememberScrollPosition(key, value) {
  if (!key) {
    key = usePostersStore.getState().selectedFeed
  }
  if (isMe(key)) {
    return
  }
  scrollPositions[key] = value || getCurrentScrollPosition()
}

export function clearAllScrollPositions() {
  scrollPositions = {}
}

export function clearScrollPositions(key) {
  delete scrollPositions[key]
}

export function getPostersScrollPosition() {
  return scrollPositions[POSTERS_SCROLL] || 0
}

export function rememberPostersScrollPosition() {
  return rememberScrollPosition(POSTERS_SCROLL)
}

export function getCurrentScrollPosition() {
  if (isDesktopSkin()) {
    const element = document.getElementById(POSTERS_GREED)
    return element ? element.scrollTop : 0
  } else {
    return window.scrollY
  }
}
