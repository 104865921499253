import { create } from 'zustand'
import { getConfig } from '../utils/urlUtils'
import { getSessionId } from '../utils'
import { showSnackbarNew } from '../modules/app/factories'
import { getZLocaleValueF } from './useAppStore'
import { getMyUid } from '../utils/my'
import { isCyber } from '../utils/cyber/cyberUtil'

export const aiAvatarStore = create((set) => ({
    selectedFileUrl: null,
    file: null,
    progress: false,
    usersVideoAvatars: {},
    onFileSelected: file => {
        set({ selectedFileUrl: file.url, file: file })
    },
    clearSelectedFile: () => {
        set({ selectedFileUrl: null, file: null, progress: false })
    },
    startUploadAIAvatar: () => {
        const { selectedFileUrl, file, setVideoAvatarData } = aiAvatarStore.getState()
        if (!selectedFileUrl) {
            return
        }
        set({ progress: true })

        const formData = new FormData()
        formData.append('image', file.file)

        const xhr = new XMLHttpRequest()
        xhr.open('POST', getConfig('postUploadUrl') + '/api/v1/upload-ai-avatar', true)
        xhr.setRequestHeader('Ft-Sid', getSessionId())

        xhr.onload = () => {
            if (xhr.status === 200) {
                console.log('Upload complete!')
                showSnackbarNew(getZLocaleValueF('set_ai_avatar_success'))
                setVideoAvatarData(xhr.response.result)
            } else {
                showSnackbarNew(getZLocaleValueF('set_ai_avatar_error'))
                setVideoAvatarData(xhr.response)
                console.log('Upload ERROR')
            }

        }

        xhr.onerror = () => {
            console.log('Upload ERROR')
            showSnackbarNew(getZLocaleValueF('set_ai_avatar_error'))
            set({ selectedFileUrl: null, file: null, progress: false })
        }
        xhr.send(formData)
    },
    setVideoAvatarData: data => {
        set({
            selectedFileUrl: null,
            file: null,
            progress: false
        })
        aiAvatarStore.getState().setUserVideoAvtar(getMyUid(), data)
    },
    getUserVideoAvtar: uid => {
        if (isCyber(uid)) {
            return {
                idle: 'https://swf-selectel.faminta1.com/vb/ai-video-avatars/' + uid + '_idle.mp4',
                talk: 'https://swf-selectel.faminta1.com/vb/ai-video-avatars/' + uid + '_talk.mp4',
            }
        }
        const { usersVideoAvatars } = aiAvatarStore.getState()
        return usersVideoAvatars[uid]
    },
    setUserVideoAvtar: (uid, data) => {
        const { usersVideoAvatars } = aiAvatarStore.getState()
        if (data) {
            usersVideoAvatars[uid] = {
                idle: data['idle-video'],
                talk: data['talk-video'],
            }
        } else {
            delete usersVideoAvatars[uid]
        }
        set({ usersVideoAvatars })
    }
}))
