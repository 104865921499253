import { sendPostViewFunc } from '../postUtils'
import { getMyLSProp, setMyLSProps } from '../LocalStorageUtil'
import { getState } from '../StoreManager'
import {
  TYPE_CYBER,
  TYPE_DATING,
  TYPE_FANS,
  TYPE_I_LIKE,
  TYPE_LIVE_ONLY, TYPE_MATCH,
  TYPE_ONLINE,
  TYPE_REAL_DATING, TYPE_SPONSORS, TYPE_VIDEO_ONLY, TYPE_VIP
} from '../../modules/posts/signals/getPosts'
import { isMobileSkin } from '../checks'
import { isCustomVanilla } from '../api/PWAUtil'
import { imInRoom } from '../appUtil'
import { isDatingApp } from '../appConfigUtil'
import { getProfileDatingCardID } from '../navigation/datingScrollUtil'
import usePostersStore from '../../zustand/usePostersStore'

//список постов, которые могут появиться на экране и нужно оценить считать ли им просмотр
const postToCheck = {}

export const MAX_SAVED_VIEWS = 400

export function addPostToViewCheck(postId) {
  postToCheck[postId] = postId
}

export function removePostFromViewCheck(postId) {
  delete postToCheck[postId]
}

//при скролле пройдемся по всем постам для анализа и узнаем в области ли они
export function startScrollViewCheck() {
  Object.keys(postToCheck).map(postId => {
    const element = document.getElementById(getProfileDatingCardID(postId))
    if (!element) return

    const rect = element.getBoundingClientRect()
    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

    if (isVisible) {
      sendPostViewOnScroll(postId)
    }
  })
}

let currentViews

function sendPostViewOnScroll(postId) {
  //удалим из списка
  removePostFromViewCheck(postId)
  sendPostViewFunc(postId)

  const feed = getState('posts.selectedFeed')
  //если лента без ограничений на просмотры то ничего не делаем
  if (!(feed === TYPE_DATING || feed === TYPE_REAL_DATING || feed === TYPE_ONLINE)) {
    return
  }

  const viewsInFeed = getViewsInFeed(feed)
  if (viewsInFeed.views.includes(postId)) {
    return
  }
  viewsInFeed.views.push(postId)
  currentViews[feed] = viewsInFeed

  setMyLSProps('views', currentViews)
}

function getViewsInFeed(feed) {
  if (!currentViews) {
    currentViews = getMyLSProp('views') || {}
  }
  return currentViews[feed] || { views: [] }
}

const FREE_VIEWS_LIMIT = 10

export function getFreeProfileViewCount() {
  const feed = getState('posts.selectedFeed')
  if (!isDatingView()) {
    return 30
  }

  const viewsInFeed = getViewsInFeed(feed)
  return FREE_VIEWS_LIMIT - viewsInFeed.views.count
}

export function isDatingView(feed) {
  if (isCustomVanilla() || (imInRoom() && !feed)) {
    return false
  }
  if (!feed) {
    if (usePostersStore.getState()) {
      feed = usePostersStore.getState().selectedFeed
    }
  }
  // const feed = getState('posts.selectedFeed')
  return feed === TYPE_DATING || feed === TYPE_REAL_DATING || feed === TYPE_ONLINE || feed === TYPE_FANS
}

export function isMobileDating() {
  return isDatingView() && isMobileSkin()
}

export function noLiveFeed(feed) {
  // const feed = getState('posts.selectedFeed')
  return isDatingView(feed) ||
      isDatingApp() ||
    feed === TYPE_I_LIKE ||
    feed === TYPE_SPONSORS ||
    feed === TYPE_VIDEO_ONLY ||
    feed === TYPE_CYBER ||
    feed === TYPE_MATCH
}

export function isCyberPostersFeed(feed) {
  return  feed === TYPE_LIVE_ONLY || feed === TYPE_CYBER
}

export function isOnlyLiveFeed() {
  return false
  // return !isDatingApp() && getState('posts.selectedFeed', TYPE_LIVE_ONLY) === TYPE_LIVE_ONLY
}

// export function isOnlyPostsFeed() {
//   // const feed = getState('posts.selectedFeed')
//   // return !isOnlyLiveFeed() && (feed === TYPE_LIVE_ONLY ||
//   //   feed === TYPE_DATING ||
//   //   feed === TYPE_REAL_DATING ||
//   //   feed === TYPE_DATING ||
//   //   feed === TYPE_VIP ||
//   //   feed === TYPE_MATCH ||
// }


export function isFansFeed() {
  const feed = getState('posts.selectedFeed')
  return feed === TYPE_FANS
}
