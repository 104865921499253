/* eslint-disable no-case-declarations */
import controller from '@/controller'
import { state } from 'cerebral'
import { generateNameFromUid } from '../modules/auth/actions/generateNameFromUid'
import config from '@/config'
import isVip from './isVip'
import { getSessionId } from '@/utils'
import { isTWA } from './twa'
import { getSequence, getState, setStoreWrapper } from './StoreManager'
import { getProfilePostId, isAnonymously } from './userUtil'
import { getPostData } from './postUtils'
import { isProfileVisible } from './ageRating/ageRatingUtil'
import { isPanelType } from '../modules/app/actions'
import { BUY_VIP, SHOP } from '../components/Panels/types'
import { isStreaming } from '../modules/socket/actions'
import { loadPostsNew } from '../modules/posts/signals/getPost'
import { isUID } from './isUID'
import { POSTRequest } from './api/requestWrapper'
import { sendAmplitudeEvent } from './GAEvent'
import { setUsers } from '../zustand/useUsersStore'

const { userloaderUrl, vbApiUrl, ftCoreUrl } = config

const callBacks = []
let loadFuncId = -1

//эта функция изменяет входящий маccив, удаляет из него дубликаты и невалидные uid
//еcли вcе запрошенные uid загружены, то вернет объект c данными, иначе false
export const isUsersLoaded = uids => {
    if (!uids) {
        return true
    }
    //выкидываем из маccива невалидные данные
    for (let i = 0; i < uids.length; i++) {
        const uid = uids[i]
        if (!uid || !isUID(uid) || uids.indexOf(uid) !== i) {
            uids.splice(i, 1)
            i--
            continue
        }
    }
    const result = {}
    for (let i = 0; i < uids.length; i++) {
        const uid = uids[i]
        const userData = controller.getState(`app.users.${uid}`)
        if (!userData) {
            return false
        }
        result[uid] = userData
    }
    return result
}

//эта функция проверяет загружены ли пользователи и их профили
export const isUsersAndProfilesLoaded = uids => {
    if (!uids) {
        return true
    }
    //выкидываем из маccива невалидные данные
    for (let i = 0; i < uids.length; i++) {
        const uid = uids[i]
        if (!uid || uid.length < 32 || uids.indexOf(uid) !== i) {
            uids.splice(i, 1)
            i--
            continue
        }
    }
    const result = {}
    for (let i = 0; i < uids.length; i++) {
        const uid = uids[i]
        const userData = controller.getState(`app.users.${uid}`)
        if (!userData) {
            return false
        }

        const profile = getProfilePostId(uid)
        if (profile && !getPostData(profile)) {
            // loadProfilePosts(uid)
            return false
        }
        result[uid] = userData
    }
    return result
}

//эта функция изменяет входящий маccив, удаляет из него дубликаты и невалидные uid
//еcли вcе запрошенные uid загружены, то вернет объект c данными, иначе false
export const getUsersAndLoadedNew = uids => {
    const result = {}
    if (!uids) {
        return result
    }
    //выкидываем из маccива невалидные данные
    for (let i = 0; i < uids.length; i++) {
        const uid = uids[i]
        if (!uid || uid.length < 32 || uids.indexOf(uid) !== i) {
            uids.splice(i, 1)
            i--
            continue
        }
    }
    const newUids = []

    for (let i = 0; i < uids.length; i++) {
        const uid = uids[i]
        const userData = controller.getState(`app.users.${uid}`)
        if (!userData) {
            newUids.push(uid)
        }
        result[uid] = userData
    }
    if (newUids.length) {
        loadUsers(newUids)
    }
    return result
}

export const getUsersList = listId => {
    switch (listId) {
        case 'all':
            return Object.keys(controller.getState('app.users'))
        case 'fans':
            return Object.keys(controller.getState('auth.fans'))
        case 'subs':
            const subs = controller.getState('auth.subs')
            const subsArray = Object.keys(subs).filter(uid => subs[uid])
            if (isVip()) {
                subsArray.unshift('vip')
            }
            return subsArray
        case 'viewers':
            const roomId = controller.getState('app.roomId')
            let result = controller.getState(`app.roomSpectators.${roomId}`) || []
            // if (result.length > 0) {
            //   result = result.slice(1)
            // }
            return result.filter(uid => uid !== roomId)
    }
    return []
}

//эта функция вернет данные о юзере, только еcли они уже были загружены
export const getUser = uid => {
    if (isAnonymously(uid)) {
        return { name: 'Anonymous', uid }
    }
    if (uid === controller.getState('auth.uid')) {
        return getMyUser()
    }
    // const subject = controller.getState('app.subject')
    // const team = isTeamMember(uid)
    // if (team && team.indexOf(subject) === -1) {
    //   return { name: 'Guest', uid }
    // }
    return controller.getState(`app.users.${uid}`)
}

export function updateUser(uid, userData) {
    const users = getState('app.users')
    users[uid] = userData
    setStoreWrapper('app.users', users)
}

export const getMyUser = () => {
    if (!controller.getState(`app.users.${controller.getState('auth.uid')}`)) {
        return null
    }
    return ({
        ...controller.getState(`app.users.${controller.getState('auth.uid')}`),
        photo: controller.getState('auth.photo'),
        name: controller.getState('auth.name'),
        uid: controller.getState('auth.uid'),
        multi_buffs: controller.getState('auth.multi_buffs'),
        storage: controller.getState('auth.storage'),
    })
}

export const UpdateMyUser = ({ store }) => {
    store.set(`app.users.${controller.getState('auth.uid')}`, getMyUser())
    const panelsOnPop = getSequence('app.panelsOnPop')
    if (!isPanelType(SHOP) && !isPanelType(BUY_VIP)) {
        panelsOnPop({ hideAll: true })
    }
}

export const isUserBannedMyCountry = uid => {
    const user = getUser(uid)

    if (user && user.storage && user.storage.bannedCountries) {
        const myCntr = controller.getState('intl.country').toLowerCase()
        if (user.storage.bannedCountries[myCntr] || user.storage.bannedCountries[myCntr.toUpperCase()]) {
            return true
        }
    }

    return false
}

export const loadUsers = (uids, callback, force) => {
    const loadedUsers = isUsersLoaded(uids)

    if (!uids || loadedUsers) {
        if (callback) {
            callback(loadedUsers)
        }
        return
    }

    uids = uids.map(uid => (uid || '').substr(0, 32))

    if (callback) {
        callBacks.push({
            f: callback,
            uids: uids
        })
    }

    usersToLoad = removeLoadedUids(usersToLoad.concat(uids))

    if (loadFuncId < 0) loadFuncId = setTimeout(startLoading, 200)
    if (force) {
        startLoading()
    }
}

let usersToLoad = []
export const loadUser = (uid, callback, force) => {
    if (!uid || !isUID(uid)) {
        setTimeout(callback, 1000, {})
        console.warn('USER LOADER ERROR! load uid: ' + uid)
        return
    }

    if (!force) {
        const loadedUsers = isUsersLoaded([uid])
        if (loadedUsers) {
            if (callback) callback(loadedUsers)
            return
        }
    }

    uid = uid.substr(0, 32)

    callBacks.push({
        f: callback,
        uids: [uid]
    })

    usersToLoad.push(uid)

    if (loadFuncId < 0) loadFuncId = setTimeout(startLoading, 200)
}

export const onLoadUsers = ({
                                props,
                                get, store
                            }) => {
    const usersObj = get(state`app.users`)
    for (const uid in props.users) {
        usersObj[uid] = { ...usersObj[uid], ...props.users[uid] }
        usersObj[uid].uid = uid
        if (!usersObj[uid].name) {
            usersObj[uid].name = generateNameFromUid(uid)
        }
        if (!usersObj[uid].lang) usersObj[uid].lang = 'en'
    }

    store.set('app.users', usersObj)

    checkCallbacks()
}

function checkCallbacks() {
    for (let i = 0; i < callBacks.length; i++) {
        const { f, uids } = callBacks[i]
        const loadedUsers = isUsersLoaded(uids)
        if (loadedUsers) {
            if (f) f(loadedUsers)
            callBacks.splice(i, 1)
            i--
        }
    }
}

function removeLoadedUids(uids) {
    //уберем дубликаты и невалидные uid
    const noDubles = uids.filter(function (item, pos) {
        return !item || item.length !== 32 || uids.indexOf(item) === pos
    })

    const result = []

    for (let i = 0; i < noDubles.length; i++) {
        const uid = noDubles[i]

        const userData = controller.getState(`app.users.${uid}`)
        if (!userData) {
            result.push(uid)
        }
    }

    return result
}

const startLoading = async () => {
    loadFuncId = -1

    if (usersToLoad.length === 0) {
        checkCallbacks()
        return
    }

    const uids = [...usersToLoad]
    if (uids.length > 200) {
        console.error('too much users to load ' + uids.length)
        sendAmplitudeEvent('too_much_to_load', { count: uids.length })
    }
    usersToLoad = []
    POSTRequest(userloaderUrl + '/api/v1/userloader', { uids }, (data) => {
        setUsers(data)
        checkSubs(data)
        checkCallbacks()
        controller.getSequence('app.onUsersLoaded')({ users: data })
        // loadProfilePosts(data)

    }, () => {
    }, true)
}

export async function loadSubsByUids(uidsToCheck, cb) {
    // const subsCount = getState('auth.subsCount', 0)
    // if (getState('auth.isAuthComplete') && !subsCount) {
    //     if (cb){
    //         cb()
    //     }
    //     return
    // }

    const subs = getState('auth.subs', {})
    uidsToCheck = uidsToCheck.filter(uid => !subs[uid])
    if (uidsToCheck.length === 0) {
        if (cb){
            cb()
        }
        return
    }

    if (getSessionId()) {
        const sResponse = await fetch(`${ftCoreUrl}/api/v1/subscriptions/authors?authors=${uidsToCheck.join()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'ft-sid': getSessionId()
            },
        }).catch(e => {
            console.warn('subscriptions/authors?authors catch error', e)
            if (cb){
                cb()
            }
        })
        if (sResponse && sResponse.ok) {
            const result = (await sResponse.json())['authors']
            const newSubs = {}
            uidsToCheck.forEach(uid => newSubs[uid] = result[uid] || false)
            controller.getSequence('auth.setSubs')({ subs: { ...getState('auth.subs', {}), ...newSubs } })
            if (cb){
                cb()
            }
        }
    }
}

export const checkSubs = async (users, cb) => {
    loadSubsByUids(Object.keys(users), cb)
}

const checkUsers = newUsers => {
    for (const uid in newUsers) {
        const user = newUsers[uid]

        if (isTWA() && !isProfileVisible(uid) && getProfilePostId(uid)) {
            user.name = 'Guest'
            user.profile_post = null
        }
    }
    return newUsers
}

// export const saveProfiles = [
//   savePostsData
// ]

/** вернул обязательную загрузку профиля для юзеров на камере */
const loadProfilePosts = users => {
    const profilePosts = []
    for (const uid in users) {
        const { profile_post } = users[uid]
        if (!profile_post || !profile_post.postId || !isStreaming(uid, true)) {
            continue
        }
        profilePosts.push(profile_post.postId)
    }

    if (profilePosts.length) {
        loadPostsNew(profilePosts, true, () => {
            // console.log('old')
        })
    }
}

export function loadUsersAndProfiles(uids, cb) {
    if (!uids || !uids.length) {
        if (cb) {
            cb()
        }
        return
    }
    loadUsers(uids, () => {
        const profilePosts = []
        uids.forEach(uid => {
            const user = getUser(uid)
            if (!user) {
                return
            }
            const { profile_post } = user
            if (!profile_post || !profile_post.postId || getPostData(profile_post.postId)) {
                return
            }
            profilePosts.push(profile_post.postId)
        })

        if (profilePosts.length) {
            loadPostsNew(profilePosts, true, () => {
                cb()
            })
        } else {
            cb()
        }
    }, true)
}

//для cerebral cигналов
export const requestUsers = async ({
                                       path,
                                       props
                                   }) => {
    if (!props.uids) {
        return path.success({
            result: []
        })
    }

    const LoadAsync = () => {
        return new Promise(
            resolve => loadUsers(props.uids, users => resolve(users))
        )
    }

    try {
        const loadedUsers = await LoadAsync()
        props.uids = []

        return path.success({
            result: loadedUsers
        })
    } catch (error) {
        return path.error({ error })
    }
}
