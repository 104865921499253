import { getState, setStoreWrapper } from '../StoreManager'
import { getMyUid, imVIP, loginCheckWithPanelOpen } from '../my'
import { checkDialogMsgPermission, needSubscription, PRIVATE_MSG_PERMISSION } from '../subscriptionUtil'
import { getFanInfo } from '../../modules/fans/fansUtil'
import { imAdmin, isSupport } from '../adminUtil'
import { getDialogs } from '../../modules/chat/actions/sendDialogMessage'
import { isMe } from '../checks'
import config from '@/config'
import { getSessionId } from '../cookies'
import { sendDialogClick } from '../amplitudeLiveEvents'
import { createDialogPanel } from '../../components/Panels/createPanelUtil'

const { dialogsUrl } = config

export function getDialogById(dialog_id) {
    const dialogs = getDialogs()
    return dialogs && dialogs.find(d => d.dialog_id === dialog_id)
}

export function updateDialogFileData(dialog_id, messageId, fileInfo) {
    // const dialogs = getState('chat.dialogs')

    const dialog = getDialogById(dialog_id)

    if (dialog && dialog.messages) {
        let msg
        for (let i = 0; i < dialog.messages.length; i++) {
            msg = dialog.messages[i]
            if (msg.message_id === messageId) {
                const msgNew = msg
                msgNew.metadata.file_info = fileInfo
                if (!msgNew.metadata.paid_info) {
                    msgNew.metadata.paid_info = {}
                }
                msgNew.metadata.paid_info.already_paid_uids.push(getMyUid())
                dialog.messages.splice(i, 1, msgNew)
                return
            }
        }
    }
}

export function isPaidDialogMessage(uid, dialog_id, noVip) {

    return false

    if (isSupport(uid)) {
        return false
    }

    if (imAdmin()) {
        return false
    }

    if (getFanInfo(uid)) {
        return false
    }

    //если какое то не стандратное ограничение, то нет блока про цену
    if (needSubscription(uid, PRIVATE_MSG_PERMISSION)) {
        if (noVip) {
            return false
        }
        return !imVIP()
    }

    const messages = getDialog(dialog_id, uid).messages
    //если есть сообщения в диалоге и не системное, значит бесплатно
    // && messages.find(message => message.message_type !== MESSAGE_TYPE_SYSTEM) убрал ограничение на не системное
    if (messages.length) {
        return false
    }

    return true
}

let newDialogsMap = {}

export function addUnreadDirectMessage(dialog_id, count) {
    newDialogsMap[dialog_id] = (newDialogsMap[dialog_id] || 0) + count
    setStoreWrapper('unreadDialogMessage', (getState('unreadDialogMessage') || 0) + count)
}

export function clearUnreadDirectMessage() {
    newDialogsMap = {}
    setStoreWrapper('unreadDialogMessage', null)
}

export function markReadUnreadDirectMessage(dialog_id) {
    if (getState('unreadDialogMessage')) {
        let currentMessage = newDialogsMap[dialog_id]
        if (currentMessage) {
            let unread = getState('unreadDialogMessage')
            if (unread >= currentMessage) {
                setStoreWrapper('unreadDialogMessage', (unread - currentMessage))
            }
            delete newDialogsMap[dialog_id]
        }
    }
}

export function getUnreadMessageCount(dialog_id) {
    return newDialogsMap[dialog_id] || 0
}

export function getDialogCost() {
    const settings = getState('intl.settings')
    if (!settings) {
        return 95
    }
    return settings.dialogMessageCost || 95
}

export function getDialog(dialog_id, uid, otherUid) {
    if (dialog_id === 'AllDialogsMedia' || otherUid) {
        const od = getState('chat.otherDialogs') || {}
        if (dialog_id === 'AllDialogsMedia') {
            if (od[dialog_id]) {
                return (od[dialog_id]['dialogs'] && od[dialog_id]['dialogs'][0]) || []//   od[props.otherUid] = { dialogs: [] }
            }
        }
        if (otherUid && od[otherUid] && od[otherUid]['dialogs']) {
            return od[otherUid]['dialogs'].find(
                dialog_id ?
                    d => d.dialog_id === dialog_id : d => d.d.users_in_dialog.includes(uid)
            )
        }
    }

    const dialogs = getDialogs()
    let dialog = dialogs.find(d => d.companionUid === uid || d.dialog_id === dialog_id)
    if (!dialog) {
        dialog = {
            date: new Date().toISOString(),
            companionUid: uid,
            messages: [],
            users_in_dialog: isMe(uid) ? [uid, otherUid] : [
                getMyUid(),
                uid
            ],
        }
        if (dialog_id) {
            dialog.dialog_id = dialog_id
        }
        dialogs.push(dialog)
        setStoreWrapper('chat.dialogs', dialogs)
    }

    if (!dialog.messages) {
        dialog.messages = []
    }

    return dialog
}

export async function deleteDialogMessage(messageId) {
    const response = await fetch(`${dialogsUrl}/api/v1/messages/${messageId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
        },
    })
    if (!response.ok) return
}

export async function deleteDialog(dialogId) {
    if (!dialogId) {
        return
    }
    let dialogs = getState('chat.dialogs')
    if (dialogs) {
        dialogs = dialogs.filter(d => d.dialog_id !== dialogId)
    }

    setStoreWrapper('chat.dialogs', dialogs)
    const response = await fetch(`${dialogsUrl}/api/v1/dialogs/${dialogId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'ft-sid': getSessionId()
        },
    })
    if (!response.ok) return
}

export function onDialogClick(uid, reason, aiMode) {
    if (!loginCheckWithPanelOpen()) {
        return
    }

    if (!checkDialogMsgPermission(uid)) {
        return
    }
    // if (!isMyProfileSet() && !imVIP() && !isSupport(uid) && !imAdmin()) {
    //   datingNeedProfile('need_set_profile')
    //   return
    // }
    setStoreWrapper('forceDialogID', null)
    createDialogPanel(uid, aiMode)
    sendDialogClick(uid, reason)
}

export function isMessageRead(sendTimestamp, dialogId) {
    const sendDate = new Date(sendTimestamp)
    const dialog = getDialogById(dialogId)
    if (!dialog.users_in_dialog) {
        return false
    }
    const companionUid = dialog.users_in_dialog.find(uid => !isMe(uid))
    if (!companionUid) {
        return false
    }
    if (dialog.last_read && dialog.last_read[companionUid] && sendDate < new Date(dialog.last_read[companionUid])) {
        return true
    }

    return false
}

export function updateLastRead(data) {
    const dialogId = data.dialog_id
    const dialogs = getDialogs()
    const dialog = dialogs && dialogs.find(d => d.dialog_id === dialogId)
    if (!dialog) {
        return
    }
    if (!dialog.last_read) {
        dialog.last_read = {}
    }
    dialog.last_read[data.uid] = data.last_read
    setStoreWrapper('chat.dialogs', dialogs)
}
