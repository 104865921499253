import { send } from '../../socket/actions'
import { panelsOnPop } from '../../app/actions'
import { clearUserData } from '../actions'
import { set, unset } from 'cerebral/factories'
import { state } from 'cerebral'
import prepareAndRouteTo from '../../app/signals/prepareAndRouteTo'
import { postersRoute } from '@/utils/routerHelpers'
import { getSessionId } from '@/utils'
import { clearAllScrollPositions } from '../../../utils/scrollRememberUtil'
import { clearUnreadDirectMessage } from '../../../utils/chat/dialogUtil'
import { clearNewPostsCount } from '../../../utils/newPostsCountUtli'
import { clearLoadOnlineSubs } from '../../../utils/onlineSubsUtil'
import resetPosts from '../../posts/signals/resetPosts'
import { clearAllPosters } from '../../../utils/posters/clearAllPosters'
import { selectFeed } from '../../filters/actions/feedUtil'
import { isAIDatingApp, isDatingApp } from '../../../utils/appConfigUtil'
import { TYPE_CYBER, TYPE_LIVE_ONLY, TYPE_REAL_DATING } from '../../posts/signals/getPosts'
import { setStoreWrapper } from '../../../utils/StoreManager'

export default [
  resetPosts,
  () => {
    clearAllPosters()
    // setStoreWrapper('allLivesInPosters', [])
  },
  () => ({ method: 'logOut', params: { session: getSessionId() } }),
  send,
  clearUserData,
  () => ({ hideAll: true }),
  panelsOnPop,
  () => ({ url: postersRoute }),
  () => {
    clearAllScrollPositions()
    clearUnreadDirectMessage()
    clearNewPostsCount()
    clearLoadOnlineSubs()
    selectFeed(isAIDatingApp() ? TYPE_CYBER : isDatingApp() ? TYPE_REAL_DATING : TYPE_LIVE_ONLY)
  },
  prepareAndRouteTo,
  // () => loginPanel,
  // createPanel,
  unset(state`app.users.${state`auth.uid`}`),
  unset(state`auth.vip_subscription`),
  set(state`auth.uid`, null),
  set(state`posts.topPosts`, []),
  set(state`posts.loadingPostsStatus`, 'not_loaded'),
  set(state`posts.userPosts`, {}),
  set(state`posts.similarPosts`, {}),
  set(state`auth.photo`, null),
  set(state`auth.stuff`, {}),
  set(state`auth.storage`, {}),
  set(state`auth.subs`, {}),
  set(state`auth.subsCount`, 0),
  set(state`auth.fans`, {}),
  set(state`auth.multi_buffs`, {}),
  set(state`auth.fansCount`, 0),
  set(state`posts.allUserPostsLoaded`, {}),
  set(state`posts.allSimilarPostsLoaded`, {}),
  set(state`app.eulaAccepted`, false),
  set(state`chat.dialogsLoaded`, false),
  set(state`chat.dialogs`, null),
  set(state`chat.dialogsPage`, 0),
  set(state`auth.admin`, false),
  set(state`auth.superAdmin`, false),
  set(state`auth.firstViewed`, false),
  set(state`auth.profilePostTags`, []),
  set(state`auth.streamToken`, null),
  set(state`auth.myStreamId`, null),
  set(state`allMyDataLoaded`, false),
  set(state`myBDay`, null),
  ()=>{
    setTimeout(()=>{ setStoreWrapper('genderSelectScreen', true) }, 500)
  }
]
