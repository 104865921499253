import { POSTRequest } from '../api/requestWrapper'
import { getConfig } from '../urlUtils'
import { getDialog } from './dialogUtil'
import { isMe } from '../checks'
import { isCyber } from '../cyber/cyberUtil'
import { setStoreWrapper } from '../StoreManager'

let autoDialogTimeout

function initAutoDialog(uid) {
    POSTRequest(getConfig('dialogsUrl') + '/api/v1/dialogs/autostart-ai', { uid_to:uid },
        data => {
            if (data.dialog_id) {
                setStoreWrapper('forceDialogID', data.dialog_id)
                //создает диалог если его нет
                getDialog(data.dialog_id, uid)
            }
        })
}

export function checkAutoStartDialog(uid) {
    if (!isMe(uid) && isCyber(uid)) {
        const dialog = getDialog(undefined, uid)
        if (dialog.messages.length < 2) {
            autoDialogTimeout = setTimeout(()=>{
                initAutoDialog(uid)
            }, 2000)
        }
    }
}

export function cancelAutoDialog() {
    if (autoDialogTimeout) {
        clearTimeout(autoDialogTimeout)
    }
}
