import { generateFingerprint, requestToken } from '../actions'
import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'
import { setMyGender } from '../../../utils/api/setGenderUtil'
import { profileFillSnackbar } from '../../../utils/snackbarUtil'
import { showSnackbarNew } from '../../app/factories'
import { createLoginPanel } from '../../../components/Panels/createPanelUtil'
import * as Sentry from "@sentry/react";

export default [
  set(state`auth.isAuthComplete`, false),
  requestToken, {
    success: [
      ({ props }) => {
        setMyGender(props.gender, () => {
          callSequence('auth.getAuthInfo')
          setTimeout(()=>{
            profileFillSnackbar()
          }, 3500)
        })
      }
    ],
    error: [
      generateFingerprint,
      set(state`auth.token`, props`fingerprint`),
      requestToken, {
        success: [
          ({ props }) => {
            setMyGender(props.gender, () => {
              callSequence('auth.getAuthInfo')
              setTimeout(()=>{
                profileFillSnackbar()
              }, 2500)
            })
          }
        ],
        error: [()=>{
          Sentry.captureMessage('auth ERROR')
          Sentry.captureException('auth ERROR')
          console.error('auth ERROR')
          setStoreWrapper('auth.isAuthComplete', true)
          callSequence('auth.logOut')
          showSnackbarNew('gu_auth_error')
          createLoginPanel()
        }]
      }
    ]
  }
]
