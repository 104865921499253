import { callSequence, getState, setStoreWrapper } from '../../../utils/StoreManager'
import {
    TYPE_CHAT,
    TYPE_CYBER,
    TYPE_DATING,
    TYPE_FANS,
    TYPE_I_LIKE,
    TYPE_LIVE_ONLY,
    TYPE_MATCH,
    TYPE_ONLINE,
    TYPE_REAL_DATING,
    TYPE_SPONSORS,
    TYPE_SUBS_POSTS,
    TYPE_TOP_M,
    TYPE_TOP_M_L,
    TYPE_TOP_W,
    TYPE_VIDEO_ONLY,
    TYPE_VIP
} from '../../posts/signals/getPosts'
import { setNewFansViewed, setNewMatchViewed } from '../../fans/fansUtil'
import { markAllPostsViewed } from '../../../utils/newPostsCountUtli'
import { clearAllPosters } from '../../../utils/posters/clearAllPosters'
import { clearDatingCash } from '../../../utils/api/dating/datingApi'
import { imVIP } from '../../../utils/my'
import { createGetVipPanel, createTopPanel } from '../../../components/Panels/createPanelUtil'
import { useSetSelectedFeed } from '../../../zustand/usePostersStore'
import { sendPercentAmplitudeEvent } from '../../../utils/GAEvent'

// import { deactivateSponsors } from '../../../components/Screens/Posters/Filters'
export const EXP_M = 'experience_monthly'
export const EXP_M_COUNTRY = 'experience_monthly_country'
export const EXP_W = 'experience_weekly'

export function selectFeed(id) {
    if (checkSelectedFeed(id)) {
        return
    }

    if (id === TYPE_VIP && !imVIP()) {
        createGetVipPanel('vip_only_posts')
        return
    }

    if (id === TYPE_TOP_M) {
        createTopPanel(EXP_M)
        sendPercentAmplitudeEvent('top_click', { type: 'month' })
        return
    }

    if (id === TYPE_TOP_M_L) {
        createTopPanel(EXP_M_COUNTRY)
        sendPercentAmplitudeEvent('top_click', { type: EXP_M_COUNTRY })
        return
    }

    clearAllPosters()

    if (id === TYPE_FANS) {
        clearDatingCash(TYPE_FANS)
        setNewFansViewed()
    } else if (id === TYPE_MATCH) {
        clearDatingCash(TYPE_MATCH)
        setNewMatchViewed()
    } else if (id === TYPE_SUBS_POSTS) {
        markAllPostsViewed()
    }

    if (id === TYPE_I_LIKE) {
        clearDatingCash(TYPE_I_LIKE)
    }

    // if (id === TYPE_CYBER) {
    //   setCyberPosters()
    // }

    // else if (id === TYPE_DATING){
    //   checkGEONotification()
    // }
    // if (id !== TYPE_CYBER){

    // }
    setStoreWrapper('posts.selectedFeed', id)

    useSetSelectedFeed(id)
    if (id !== TYPE_TOP_M && id !== TYPE_TOP_W && id !== TYPE_TOP_M_L && id !== TYPE_CYBER) {
        callSequence('posts.getMorePosts')
    }
}

export function disableFeed(id) {
    const filters = getState('posts.filters')
    filters.map(item => {
        const groupId = Object.keys(item)[0]
        item[groupId].map(i => {
            if (typeof i !== 'number') {
                if (i.id === id && i.selected) {
                    i.selected = false
                }
            }
        })
    })

    setStoreWrapper('posts.selectedFeed', null)
}

export function isOnlySubs() {
    return getState('posts.selectedFeed') === TYPE_SUBS_POSTS
}

export function isOnlyVIP() {
    return getState('posts.selectedFeed') === TYPE_VIP
}

export function checkSelectedFeed(value) {
    return getState('posts.selectedFeed') === value
}

export function getSelectedTags() {
    const tags = []
    const filters = getState('posts.filters')
    filters.map(item => {
        const groupId = Object.keys(item)[0]
        item[groupId].map(i => {
            if (i.selected && !isMainFeedType(i.id) && i.tagsId && i.tagsId.length) {
                tags.push(i.tagsId[0])
            }
        })
    })
    return tags
}

export function isMainFeedType(id) {
    return (id === TYPE_DATING ||
        id === TYPE_REAL_DATING ||
        id === TYPE_I_LIKE ||
        id === TYPE_ONLINE ||
        id === TYPE_FANS ||
        id === TYPE_CHAT ||
        id === TYPE_LIVE_ONLY ||
        id === TYPE_MATCH ||
        id === TYPE_SPONSORS ||
        id === TYPE_VIP ||
        id === TYPE_VIDEO_ONLY ||
        id === TYPE_TOP_M ||
        id === TYPE_TOP_W ||
        id === TYPE_CYBER ||
        id === TYPE_SUBS_POSTS)
}

export function isLikesViewFeed() {
    const feed = getState('posts.selectedFeed')
    return feed === TYPE_MATCH || feed === TYPE_I_LIKE
}

