import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import { getSessionId } from '@/utils'
import { hideBadUrlsInDialog } from './checkChatMessage'
import { getDialog, markReadUnreadDirectMessage } from '../../../utils/chat/dialogUtil'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { checkAutoStartDialog } from '../../../utils/chat/autoDialog'
import { isCyber } from '../../../utils/cyber/cyberUtil'
import { MESSAGE_TYPE_SYSTEM } from '../../../utils/manyConst'
import { usersVoices } from '../../../utils/ai/textToSpeech'
import { isMe } from '../../../utils'
import { getMyUid } from '../../../utils/my'
import { aiAvatarStore } from '../../../zustand/aiAvatarStore'

const { dialogsUrl } = config

export default async ({ store, get, props }) => {
  const myUid = get(state`auth.uid`)
  if (!myUid) {
    return
  }
  const od = getState('chat.otherDialogs') || {}
  if (!od[props.otherUid]) {
    od[props.otherUid] = { dialogs: [] }
  }

  setStoreWrapper('chat.otherDialogs', od)

  const dialogs = props.otherUid ?
    (get(state`chat.otherDialogs.${props.otherUid}.dialogs`) || []) :
    (get(state`chat.dialogs`) || [])

  const save = () => {
    if (props.otherUid) {
      store.set(`chat.otherDialogs.${props.otherUid}.dialogs`, dialogs)
    } else {
      store.set('chat.dialogs', dialogs)
    }
  }

  const dialog = getDialog(props.dialog_id , props.uid, props.otherUid)
  // if (myUid === props.uid){
  //   dialog = dialogs.find(
  //     props.dialog_id ?
  //       d => d.dialog_id === props.dialog_id :
  //       d => d.users_in_dialog.length === 1 && (d.users_in_dialog[0] === props.uid)
  //   )
  //   console.log('some')
  // } else {
  // dialog =
  // }

  // if (!dialog) {
  //   if (myUid === props.uid) {
  //     dialog = {
  //       date: new Date().toISOString(),
  //       dialog_id: props.dialog_id || null,
  //       users_in_dialog: [
  //         myUid
  //       ],
  //     }
  //   } else {
  //     dialog = {
  //       date: new Date().toISOString(),
  //       dialog_id: props.dialog_id || null,
  //       companionUid: props.uid,
  //       users_in_dialog: [
  //         myUid,
  //         props.uid
  //       ],
  //     }
  //   }
  //
  //   dialogs.push(dialog)
  // }
  markReadUnreadDirectMessage(dialog.dialog_id)

  dialog.unread_message_count = 0
  if (dialog.loading || dialog.loadedAllMessages) {
    return
  }

  dialog.loading = true
  save()

  const page = dialog.page || 1

  let result
  try {
    const response = await fetch(
      props.otherUid ?
        props.otherUid === 'AllDialogsMedia' ? `${dialogsUrl}/api/v1/dialogs/media?page=${page}` :
          `${dialogsUrl}/api/v1/dialogs/${dialog.dialog_id}?uid=${props.otherUid}&page=${page}` :
        dialog.dialog_id ?
          `${dialogsUrl}/api/v1/dialogs/${dialog.dialog_id}?page=${page}` :
          `${dialogsUrl}/api/v1/dialogs/uid/${props.uid}?page=${page}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'ft-sid': getSessionId()
        },
      }
    )
    if (!response.ok) return
    result = await response.json()

    if (!dialog.messages) {
      dialog.messages = []
    }
    if (props.otherUid === 'AllDialogsMedia') {
      dialog.users_in_dialog = []
    }
    dialog.messages = [...result.dialog.filter(
      m => !dialog.messages.find(m2 => m2.message_id === m.message_id)
    ), ...dialog.messages]

    dialog.messages.forEach(msg => {
      if (props.otherUid === 'AllDialogsMedia' && !dialog.users_in_dialog.includes(msg.uid)) {
        dialog.users_in_dialog.push(msg.uid)
      }
      if (msg.uid === myUid || get(state`auth.superAdmin`) || get(state`auth.admin`)) return
      msg.message = hideBadUrlsInDialog(msg.message)
      if (msg.translations) {
        for (const key in msg.translations) {
          msg.translations[key] = hideBadUrlsInDialog(msg.translations[key])
        }
      }
    })
    dialog.page = page + 1
    if (!dialog.dialog_id && dialog.messages.length > 0) {
      dialog.dialog_id = dialog.messages[0].dialog_id
      dialog.date = dialog.messages[0].date
      dialog.message = dialog.messages[0].message
      dialog.users_in_dialog = result.users_in_dialog
    }

    dialog.companionUid = dialog.users_in_dialog.find(uid => !isMe(uid))
    if (!dialog.companionUid) {
      dialog.companionUid = getMyUid()
    }
    const cyberUID = dialog.users_in_dialog.find(uid => isCyber(uid))
    if (dialog.messages.length < 20 && dialog.page === 2 && cyberUID && !dialog.messages.find(msg => msg.id === 'info')) {
      dialog.messages.unshift({
        type: MESSAGE_TYPE_SYSTEM,
        id: 'info',
        text: 'ai_friend_descr_' + cyberUID
      })
    }
    dialog.loadedAllMessages = result.dialog.length === 0
    dialog.last_read = result.last_read
    if (result.to_user_ai_info) {
      if (result.to_user_ai_info.voice){
        usersVoices[dialog.companionUid] = result.to_user_ai_info.voice
      }

      if (result.to_user_ai_info.avatar_upload_time){
        aiAvatarStore.getState().setUserVideoAvtar(dialog.companionUid, {
          'idle-video': 'https://ft-ai-video-avatars.akamaized.net/avatars/' + dialog.companionUid + '/idle.mp4?v=' + result.to_user_ai_info.avatar_upload_time,
          'talk-video': 'https://ft-ai-video-avatars.akamaized.net/avatars/' + dialog.companionUid + '/talk.mp4?v=' + result.to_user_ai_info.avatar_upload_time
        })
      }
    }
    loadUsers(
      dialog.users_in_dialog,
      () => {
        dialog.loading = false
        save()
        checkAutoStartDialog(props.uid)
      }
    )
  } catch (e) {
    dialog.loading = false
    save()
  }
}
