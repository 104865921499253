import { state } from 'cerebral'
import { getMorePosts, getMoreSimilarPosts, getMoreUserPosts } from '../../posts/signals/getPosts'
import { getSponsors } from '../../sponsors/signals/loadSponsors'
import { getMoreContentModeration } from '../../moderation/signals/getModerationContents'
import { getLastOrSimilarPostUid } from '../../../utils/getLastOrSimilarPostUid'
import { rememberScrollPosition } from '../../../utils/scrollRememberUtil'

export default [
  ({ path, get }) => {
    switch (get(state`app.route`)) {
      case 'home':
        if (get(state`sponsors.selectedSponsors`) === true) {
          return path.loadMoreSponsors()
        } else {
          return path.loadPosters()
        }
      case 'room':
        if (get(state`posts.allUserPostsLoaded`) && get(state`posts.allUserPostsLoaded`)[get(state`app.roomId`)]) {
          return path.loadMoreSimilarToAuthor()
        } else {
          return path.loadUserPosts()
        }
      case 'post':
        if (get(state`posts.allUserPostsLoaded`) && get(state`posts.allUserPostsLoaded`)[get(state`posts.postsData.${state`posts.selectedPostId`}.uid`)]) {
          return path.loadMoreSimilarPosts()
        } else {
          return path.loadMoreAuthorPosts()
        }
      case 'contentModeration':
        return path.loadModerationContent()
    }
    return path.doNothing()
  }, {
    doNothing: [],
    loadPosters: [
      () => {
        rememberScrollPosition()
      },
      getMorePosts
    ],
    loadUserPosts: [
      ({ get }) => ({ uid: get(state`app.roomId`) }),
      getMoreUserPosts
    ],
    loadMoreAuthorPosts: [
      ({ get }) => ({ uid: get(state`posts.postsData.${state`posts.selectedPostId`}.uid`) }),
      getMoreUserPosts
    ],
    loadMoreSimilarPosts: [
      ({ get }) => ({ currentPostId: get(state`posts.selectedPostId`) }),
      getMoreSimilarPosts
    ],
    loadMoreSimilarToAuthor: [
      ({ get }) => {
        return ({ currentPostId: getLastOrSimilarPostUid(get(state`app.roomId`)) })
      },
      getMoreSimilarPosts
    ],
    loadMoreSponsors: [getSponsors],
    loadModerationContent: [getMoreContentModeration],
  }
]

